<template>
    <v-container fluid style="margin-bottom:100px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card class="rounded-xl p-2">
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    @change="(event) => updateDepartment(event)"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    disabled
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="department"
                                    :items="departments"
                                    item-value="dept_id"
                                    item-text="dept_name"
                                    :label="$t('labels.department')"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2" md="2">
                                <v-autocomplete
                                    clearable
                                    solo
                                    v-model="approve"
                                    :items="approves"
                                    item-value="value"
                                    item-text="descr"
                                    :label="$t('labels.approved')"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="2" md="2">
                                <v-autocomplete
                                    clearable
                                    solo
                                    v-model="status"
                                    :items="statuses"
                                    item-value="value"
                                    item-text="status"
                                    :label="$t('labels.status')"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        :label="$t('labels.dateFrom')"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        :label="$t('labels.dateTo')"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" >
                                <v-btn
                                class="mr-2 border-12"
                                color="error"
                                elevation="2"
                                large
                                @click="search()"
                                >{{$t('buttons.search')}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card v-show="display == 'none' ? false: true" class="rounded-xl p-2">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>{{$t('labels.Result')}}
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        :label="$t('labels.searchPrId')"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="purchases"
                                    :options.sync="options"
                                    :loading="loading"
                                    :loading-text="$t('messages.loadingMessage')"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`header.pr_id`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dt_pr`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`item.approve1`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <div v-if="$store.getters.UsernameUser === user_approve1">
                                                    <v-chip
                                                    class="ma-2"
                                                    color="primary"
                                                    v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && item.dt_aprv2 == '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    @click="setApprove1(item)" v-on="on"
                                                    >
                                                        <v-icon center> 
                                                        mdi-check-circle
                                                        </v-icon>
                                                        {{$t('buttons.confirmation')}}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 != '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    @click="setUnApprove(item)" v-on="on"
                                                    >
                                                        <v-icon center> 
                                                        mdi-replay
                                                        </v-icon>
                                                         {{$t('buttons.confirmation')}}
                                                    </v-chip>
                                                    <!-- <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    text-color="white"
                                                    v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 != '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    >
                                                        {{item.dt_aprv}}
                                                    </v-chip> -->
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_rjc != '1900-01-01 00:00:00.000'"
                                                    >
                                                         {{$t('buttons.rejected')}}
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    >
                                                        {{$t('buttons.notApproved')}}
                                                    </v-chip>
                                                    
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    text-color="white"
                                                    v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    >
                                                        {{item.dt_aprv}}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_rjc != '1900-01-01 00:00:00.000'"
                                                    >
                                                         {{$t('buttons.rejected')}}
                                                    </v-chip>
                                                </div>
                                            </template>
                                            <span>{{$t('buttons.confirm')}}</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.approve2`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <!-- <div v-if="$store.getters.UsernameUser === user_approve2">
                                                    <v-chip
                                                    class="ma-2"
                                                    color="primary"
                                                    v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 === '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    @click="setApprove2(item)" v-on="on"
                                                    >
                                                        <v-icon center> 
                                                        mdi-check-circle
                                                        </v-icon>
                                                        {{$t('buttons.confirmation')}}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_aprv2 != '1900-01-01 00:00:00.000' && item.dt_rjc == '1900-01-01 00:00:00.000'"
                                                    @click="setUnApprove2(item)" v-on="on"
                                                    >
                                                        <v-icon center> 
                                                        mdi-replay
                                                        </v-icon>
                                                         {{$t('buttons.confirmation')}}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_rjc != '1900-01-01 00:00:00.000'"
                                                    >
                                                         {{$t('buttons.rejected')}}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-else-if="item.dt_aprv == '1900-01-01 00:00:00.000' && item.dt_aprv2 == '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    >
                                                        {{$t('buttons.notApproved')}}
                                                    </v-chip>
                                                </div> -->
                                                <!-- <div> -->
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_aprv === '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    v-on="on"
                                                    >
                                                        {{$t('buttons.notApproved')}}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    text-color="white"
                                                    v-else-if="item.dt_aprv != '1900-01-01 00:00:00.000' && item.dt_rjc === '1900-01-01 00:00:00.000'"
                                                    v-on="on"
                                                    >
                                                        {{item.dt_aprv}}
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_rjc != '1900-01-01 00:00:00.000'"
                                                    v-on="on"
                                                    >
                                                         {{$t('buttons.rejected')}}
                                                    </v-chip>
                                                <!-- </div> -->
                                            </template>
                                            <span>{{$t('buttons.confirm')}}</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>{{$t('buttons.show')}}</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>{{$t('labels.prItem')}}</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="search_detail"
                                                append-icon="mdi-magnify"
                                                :label="$t('labels.searchPrId')"
                                                single-line
                                                @keyup.enter="search_temp"
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_po_items"
                                            :items="po_items"
                                            :options.sync="option_po_items"
                                            :loading="loading3"
                                            :loading-text="$t('messages.loadingMessage')"
                                            :server-items-length="totalItem_po_items"
                                            :page.sync="pagination_item"
                                            page-count="10"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.qty) }}
                                            </template>
                                            <template v-slot:[`item.wgt`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.wgt) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">{{$t('buttons.cancel')}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialogAgree"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        {{$t('messages.approvePR')}}
                        </v-card-title>
                        <v-card-text>{{$t('messages.approvePOConfirm')}}</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogAgree = false"
                        >
                            {{$t('buttons.cancel')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             {{$t('buttons.rejected')}}
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading4"
                            :disabled="loading4"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove1()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                            {{$t('buttons.confirm')}}
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogAgree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        {{$t('messages.approvePR')}}
                        </v-card-title>
                        <v-card-text>{{$t('messages.approvePOConfirm')}}</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogAgree2 = false"
                        >
                            {{$t('buttons.cancel')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             {{$t('buttons.rejected')}}
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                            {{$t('buttons.confirm')}}
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree"
                persistent
                max-width="500"
                >
                    <v-card class="p-2">
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve this PR?
                        </v-card-title>
                        <v-card-text>{{$t('messages.approvePOConfirm')}}</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree = false"
                        >
                            {{$t('buttons.cancel')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             {{$t('buttons.rejected')}}
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                            {{$t('buttons.confirm')}}
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-dialog
                v-model="dialogDisagree2"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you Rollback Approve 2 this PR?
                        </v-card-title>
                        <v-card-text>{{$t('messages.approvePOConfirm')}}</v-card-text>
                        <v-card-actions>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialogDisagree2 = false"
                        >
                            {{$t('buttons.cancel')}}
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             {{$t('buttons.rejected')}}
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        <v-btn
                            class="ma-2"
                            :loading="loading5"
                            :disabled="loading5"
                            color="green darken-1"
                            text-color="white"
                            @click="submitUnApprove2()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                            {{$t('buttons.confirm')}}
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Griya',
                disabled: false,
                href: '/admin/gk',
                },
                {
                text: 'Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Purchase Request',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [],
            office: '',
            departments: [],
            department: '',
            approves: [
                {
                    descr: 'All',
                    value: 'all'
                },
                {
                    descr: 'Not Approved',
                    value: 'approve1'
                },
                // {
                //     descr: 'Not Approved 2',
                //     value: 'approve2'
                // },
                {
                    descr: 'Rejected',
                    value: 'reject'
                }
            ],
            approve: '',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'none',
            purchases: [],
            headers: [
                {
                    text: 'PR ID',
                    align: 'start',
                    sortable: false,
                    value: 'pr_id',
                },
                { text: 'PR Date', value: 'dt_pr', sortable: false },
                { text: 'Dept Name', value: 'dept_name', sortable: false },
                { text: 'PIC Name', value: 'pic_name', sortable: false },
                { text: 'memo', value: 'memo_txt', sortable: false },
                { text: 'Status', value: 'stat', sortable: false },
                { text: 'Approve',  value: 'approve1', align: 'center', sortable: false },
                // { text: 'Approve 2',  value: 'approve2', align: 'center', sortable: false },
                { text: 'Actions',  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            search_detail: '',
            header_po_items:[
                {
                    text: 'PR ID',
                    align: 'start',
                    sortable: true,
                    value: 'pr_id',
                },
                { text: 'PR Item', value: 'pr_item' },
                { text: 'Article', value: 'description_article' },
                { text: 'Qty', value: 'qty' },
                { text: 'Weight', value: 'wgt' },
                { text: 'Status', value: 'stat' },
                { text: 'Remark', value: 'remark' }
            ],
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'GRIYA',
                appl_id : 'WEB GRIYA'
            },
            user_approve1: '',
            user_approve2: '',
            dialogAgree: false,
            dialogAgree2: false,
            dialogDisagree2: false,
            dialogDisagree: false,
            pagination: 1,
            pagination_item: 1,
            loading_reject: false,
            sortBy: 'desc',
            sortName: '',
            searchText: false,
            deptId:'',
            deptIdArr:[]
        }
    },
    mounted(){
        
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i]['var_id'] === 'DEPTID') {
                        this.deptid = res.data.data[i]['var_value']
                        console.log(this.deptid)
                        this.deptIdArr = this.deptid.split(', ');
                        console.log(this.deptIdArr)
                    }

                    if (res.data.data[i]['var_id'] === 'APRV1') {
                        this.user_approve1 = res.data.data[i]['var_value']

                        if (this.user_approve1 != null) {
                            this.approve = 'approve1'
                            // this.getPullData(1, 10)
                        }
                    }
                }

                if (this.approve === '' && this.user_approve1 === null && this.user_approve2 === null) {
                    this.approve = 'all'
                    // this.getPullData(1, 10)
                }
                this.getOffice()
                this.getPullData(1, 10)
            })
        },
        search(){
            this.pagination = 1
            this.searchText = true
            this.getPullData(1, 10, true)
        },
        search_temp(){
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        close() {
            this.dialog = false
        },
        showItem(item){
            console.log(item);
            this.po_item = item
            this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        async getPullDataDetail(page = 1, itemsPerPage = 10, item){
            this.loading3 = true
            await axios.get(`${process.env.VUE_APP_URL}/api/ayola/purchase_request/show?search=${this.search_detail}&pr_id=${item.pr_id}&txtOffice=${item.office_id}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading3 = false
                this.po_items = res.data.data
                this.totalItem_po_items = res.data.total
                this.pagination_item = res.data.current_page
            })
        },
        sortByFunc(sortBy){
            console.log(this.sortBy, sortBy);
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.getPullData(1, 10, this.searchText)
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            // await axios.get(`${process.env.VUE_APP_URL}/api/ayola/purchase_request?search=${this.$store.state.text}&txtOffice=${this.office ? this.office : ""}&txtDepartment=${this.department ? this.department : ""}&txtApproved=${this.approve ? this.approve : ""}&txtStatus=${this.status ? this.status : ""}&start_date=${start_date ? start_date : ""}&end_date=${end_date ? end_date : ""}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
            //     headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            // })
            // .then(res => {
            //     this.display = 'block'
            //     this.loading = false
            //     this.purchases = res.data.data
            //     this.totalItems = res.data.total
            //     this.pagination = res.data.current_page
            // })
        },
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/ayola/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data

                this.office = res.data[0].office_id
                this.updateDepartment(this.office)
            });
        },
        async updateDepartment(value){
            this.loading2 = true
            // Todo blm handling null and empty
            var reqBody = {
                "office_id": value,
                "dept_id": this.deptIdArr
            }
            var data = await backendApi.fetchCore(`/api/master/ayola/department`, reqBody, false, false, false)
            this.departments = data.data
            this.loading2 = false
            // axios.get(`${process.env.VUE_APP_URL}/api/master/ayola/department?office_id=${value}`, { 
            //     headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            // })
            // .then(res => {
            //     this.departments = res.data
            //     this.loading2 = false
            // });
        },
        setApprove1(item){
            this.dialogAgree = true
            this.po_item = item
        },
        setApprove2(item){
            this.dialogAgree2 = true
            this.po_item = item
        },
        setUnApprove(item){
            this.dialogDisagree = true
            this.po_item = item
        },
        setUnApprove2(item){
            this.dialogDisagree2 = true
            this.po_item = item
        },
        async submitReject(){
            this.loading_reject = true

            await axios.post(`${process.env.VUE_APP_URL}/api/ayola/purchase_request/reject`, {
                'pr_id': this.po_item.pr_id
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading_reject = false
                this.dialogAgree = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Reject",
                    visible: true
                };
            }).catch(err => {
                this.loading_reject = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitApprove1(){
            this.loading4 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/ayola/purchase_request/approve`, {
                'pr_id': this.po_item.pr_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading4 = false
                this.dialogAgree = false
                this.getPullData()
                this.submitApprove2()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 1",
                    visible: true
                };
            }).catch(err => {
                this.loading4 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitApprove2(){
            // this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/ayola/purchase_request/approve`, {
                'pr_id': this.po_item.pr_id,
                'approve': 2
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                // this.loading5 = false
                this.dialogAgree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Approve 2",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitUnApprove(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/ayola/purchase_request/unapprove`, {
                'pr_id': this.po_item.pr_id,
                'approve': 1
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogDisagree = false
                this.getPullData()
                this.submitUnApprove2()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully UnApprove 1",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async submitUnApprove2(){
            this.loading5 = true

            await axios.post(`${process.env.VUE_APP_URL}/api/ayola/purchase_request/unapprove`, {
                'pr_id': this.po_item.pr_id,
                'approve': 2
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.loading5 = false
                this.dialogDisagree2 = false
                this.getPullData()

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully UnApprove 2",
                    visible: true
                };
            }).catch(err => {
                this.loading5 = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.approve) {
                    this.getPullData(page, itemsPerPage, this.searchText)
                }
            },
            deep: true,
        },
        option_po_items: {
            handler () {
                this.loading5 = true
                const { page, itemsPerPage } = this.option_po_items
                if (this.po_item) {
                    this.getPullDataDetail(page, itemsPerPage, this.po_item)
                }
            },
            deep: true,
        },
    }
}
</script>